<template>
<div class="rowp-4 h-100">
        <div class="col-md-12">
                      <div class="mb-3">
                        <span class="h4 titulosg" v-if="accion=='editar'" >Modificar Matrícula</span>
                         <span class="h4 titulosg" v-if="accion=='nueva'">Nueva Matrícula</span>  
                         <br>
                         <span class="text-secondary">Nuevo registro de matrícula académica </span>
                      </div>
            
            <div class="bg-white p-4">
            <form @submit.prevent="NuevaActualizarMatricula" method="POST" >              

                        <p>
                          Complete el siguiente formulario, los campos con <span class="text-danger">*</span> son necesarios para finalizar el registro
                        </p>
                        
                        <div class="row mb-3 mt-3">
                            <span class="h6 titulosg">
                              Información Académica
                            </span>
                            <div class="col-md-3">
                              <label for="vigencia" class="form-label"> Vigencia de la Matrícula:  <span class="text-danger">*</span></label>
                              <br>
                              <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" class="btn-check" name="btnradio" id="vigenciactual" autocomplete="off" required  :value="listavigencias" v-model="matricula.vigencia">
                                <label class="btn btn-outline-primary titulosg" for="vigenciactual"> {{listavigencias}} </label>

                                <input type="radio" class="btn-check" name="btnradio" id="vigenciaproximoanio" autocomplete="off" required :value="listavigencias+1"  v-model="matricula.vigencia">
                                <label class="btn btn-outline-primary titulosg" for="vigenciaproximoanio">{{listavigencias+1}}</label>
                              </div>
                              
                            </div>
                            <div class="col-md-3">
                              N° Matrícula:
                              <div class="card pe-3 pt-1 text-end align-middle" style="min-height: 40px;">
                                  <span class="h3"> {{ ('00' + matricula.numeromatricula).slice (-4)}} </span>
                              </div>
                            </div>
                      </div>

                        <div class="row mb-1">

                          <div class="col-md-6">
                          <label for="sede" class="form-label">Institución / Sede:  <span class="text-danger">*</span></label>
                            <select class="form-select" required  v-model="matricula.empresasede_idempresasede">
                              <option  v-for="item in sedespermitidas" :value="item.idempresasede" :key="item.idempresasede">{{item.nombreempresasede}}</option>
                            </select>
                          </div>
                        </div>


                        <div class="row mb-2">
                          <div class="col-md-3">
                          <label for="Grado" class="form-label">Grado:  <span class="text-danger">*</span></label>
                            <select class="form-select" required  v-model="matricula.acagradosgrupos_idacagradosgrupos" :disabled="listagrados.length == 0">
                              <option  v-for="item in listagrados" :value="item.idacagradosgrupos" :key="item.idacagradosgrupos">{{item.nombregradogrupo}}</option>
                            </select>
                          </div>
              
                          <div class="col-md-3">
                          <label for="jornada" class="form-label">Jornada:  <span class="text-danger">*</span></label>
                            <select class="form-select" v-model="matricula.acajornada_idacajornada">
                              <option v-for="item in listajornadas" :key="item.idacajornada" :value="item.idacajornada">{{item.jornada}}</option>
                            </select>
                          </div>
                        </div>
 
                        <div class="h6 titulosg mt-4">Información de Procedencia Académica</div> 
                        <div class="row mb-1">

                            <div class="col-md-6">
                            <label for="institucion" class="form-label">Institución:</label>
                              <input type="text" class="form-control" id="institucionprocedencia"  v-model="matricula.nombreprocedencia">
                            </div>

                            <div class="col-md-3">
                            <label for="sector" class="form-label">Sector: </label>
                              <select class="form-select"  v-model="matricula.procedencia">
                                <option value="1">Oficial</option>
                                <option value="2">No Oficial</option>
                              </select>
                            </div>
                  
                        </div>
                        
                        <div class="row mb-1">
                          <div class="col-md-3">
                          <label for="depto" class="form-label"> Departamento:</label>
                            <select class="form-select"  v-model="departamentoseleccionado">
                              <option v-for="item in listadepartamentos" :key="item.idciudad" :value="item.idciudad"> {{item.nombreciudad}} </option>
                            </select>
                          </div>
        
                          <div class="col-md-3">
                          <label for="ciudad" class="form-label"> Ciudad:</label>
                            <select class="form-select" v-model="matricula.ciudadprocedencia" :disabled="listaciudades.length == 0">
                              <option v-for="item in listaciudades" :key="item.idciudad" :value="item.idciudad"> {{item.nombreciudad}} </option>
                            </select>
                          </div>
                        </div>


                        <div class=" mb-4">
                          <label for="ciudad" class="h6 titulosg mt-4"><strong> Programas Sociales:</strong></label>

                            <div class="form-check">
                              <input class="form-check-input" type="checkbox"   true-value="1"  false-value="0"  :checked="matricula.sisben==1"  id="sisben" v-model="matricula.sisben">
                              <label class="form-check-label" for="sisben">
                              SISBEN  
                              </label>
                            </div>

                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" true-value="1"  false-value="0"  :checked="matricula.familias==1"   id="familias" v-model="matricula.familias">
                              <label class="form-check-label" for="familias">
                                Más Familias en Acción
                              </label>
                            </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-md-6">
                            <div class=" pt-0">
                              <label for="ciudad" class="col-form-label"> <strong>Estado actual la matrícula:</strong></label>
                              <select required class="form-select" v-model="matricula.estadomatricula">
                                <option value="1">Matrícula Activa </option>
                                <option value="2"> Aprobado </option>
                                <option value="3">Promovido</option>
                                <option value="4">Repitente</option>
                                <option value="5">Reprobado </option>
                                <option value="0">Cancelada</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        
                        <!-- 3 condiciones 1. que existan matriculas registradas en el sistema activas en la vigencia seleccionada 2. que el estado de la matricula se activo y 3. no sea difente a la que se esta editando-->
                        <div  v-if="(matriculasactivas.length > 0) && (matricula.estadomatricula == 1) " class="border-start border-3 border-warning p-4 mb-3" style="background-color: #F8F9FA;" >

                              <div class="mb-4">
                                <img :src="imglocal+'/imagenes/ico/notificacionalerta.svg'" style="width: 70px;" class="me-3 float-start" alt=""> 
                                <span class="titulosg h5"> ¡ Existe una matrícula activa para la vigencia <span class="text-primary">{{matricula.vigencia}}</span> ! </span>  <br>                
                                  El estudiante registra matrícula con el estado activo para la vigencia seleccionada, es importante que solo una ellas se encuentre en este estado. 
                                   
                                </div>
                                
                                A continuación se listan las matrículas existentes y la opción para cambiar el estado.
                                <div v-for="item in matriculasactivas" :key="item.idacamatricula" class="mt-2 p-3" style="background-color: rgb(230, 235, 240);">
                                  
                                <span class="badge bg-secondary" v-if="item.estadomatricula==0"> Cancelada </span>     
                                <span class="badge bg-success" v-if="item.estadomatricula==1"> Matrícula Activa </span>    
                                <span class="badge bg-secondary" v-if="item.estadomatricula==2"> Aprobado </span>    
                                <span class="badge bg-secondary" v-if="item.estadomatricula==3"> Promovido </span>  
                                <span class="badge bg-secondary" v-if="item.estadomatricula==4"> Repitente </span>    
                                <span class="badge bg-danger" v-if="item.estadomatricula==5"> Reprobado </span>  
                                
                                <strong> <span class="ms-3"> N° {{ ('000' + item.numeromatricula).slice (-4)}} - {{item.vigencia}}  </span></strong> 
                                <span class="ms-3 text-capitalize"> {{formatoFecha(item.created_at)}} </span>                                

                              </div>
                
                              <div class="mt-3">
                                  <label for="" class="form-label"><strong>Cambiar estado a</strong>:</label>
                                  <select class="form-select" v-model="cambioestadomatriculasactivas" required>
                                    <option value="2">Aprobado</option>
                                    <option value="3">Promovido</option>
                                    <option value="4">Repitente</option>
                                    <option value="5">Reprobado </option>
                                    <option value="0">Cancelada</option>
                                  </select>

                              </div>
                        </div>

                        <div class="col-md-6 mb-4">
                            <label for="observacion" class="form-label">Observación:</label>
                            <textarea name="observacion" id="observacion" v-model="matricula.observacion" class="form-control"   rows="4"></textarea>
                        </div>
                    

                       <div>
                          <button type="submit" class="btn btn-primary me-2" :disabled="mostrar"> 
                            <div class="spinner-border spinner-border-sm me-3" role="status" v-if="mostrar">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                              <i class="fas fa-save me-1"></i> Guardar 
                          </button>
                          <button type="button" class="btn btn-light"  @click="$router.go(-1)">Cancelar </button>
                       </div>

              </form>
            </div>
 
        </div>
</div>
</template>

<script>
    import { ref, reactive, watch, onMounted } from 'vue' 
    import moment from 'moment'   
    import axios from 'axios'

export default {
     props: ['id','accion','idmatricula'],
    setup(props) {
      
        const accion = ref(props.accion)

        //variable para ocultar el boton mostrar
        const mostrar = ref(false)

        const sedespermitidas = ref()
        const listagrados = ref([])
        const listajornadas = ref([])
        
        const listadepartamentos = ref([])
        const departamentoseleccionado = ref('')
        const listaciudades = ref([])

        const datosmatricula = ref({})

        const today = new Date()
        const listavigencias = today.getFullYear()

        const matriculasactivas = ref([]) // Matriculas Activas del estudiante
        const cambioestadomatriculasactivas = ref(3) // nuevo estado para las matriculas activas                 

        const matricula = reactive({          
            idacamatricula: '',
            empresasede_idempresasede: '',
            acagradosgrupos_idacagradosgrupos: '',
            personas_idpersonas: props.id,
            acajornada_idacajornada: '',
            numeromatricula: '',
            vigencia: '',
            sisben: '',
            familias: '',
            estadomatricula: 1,
            procedencia: '',
            nombreprocedencia: '',
            ciudadprocedencia: 0,
            observacion: '',
        })
 
        const sedes = () => {
            axios.get('/api/sedesautorizadas').then( response => {
              sedespermitidas.value = response.data;
            })

        }
        const gradosxsede = (idsede) => {
            axios.post('/api/gradosxsede',  {data: [idsede]} ).then( response => {
              listagrados.value = response.data;
            })

        }

        const jornadas = () => {
            axios.get('/api/jornadas').then( response => {
              listajornadas.value = response.data;
            })
        }

        const departamentos = () => {
            axios.get('/api/departamentos').then( response => {
              listadepartamentos.value = response.data;
            })
        }
 
        const formatoFecha = (date) => {
                return moment(date).format('DD [de] MMMM [de] YYYY');
            }

        const ConsultarMatricual = () => {

            axios.get('/api/infomatricula/'+props.idmatricula).then( response => {
              
              matricula.idacamatricula = response.data.idacamatricula
              matricula.empresasede_idempresasede = response.data.empresasede_idempresasede
              matricula.acagradosgrupos_idacagradosgrupos = response.data.acagradosgrupos_idacagradosgrupos
              matricula.personas_idpersonas = response.data.personas_idpersonas
              matricula.acajornada_idacajornada = response.data.acajornada_idacajornada
              matricula.numeromatricula = response.data.numeromatricula
              matricula.vigencia = response.data.vigencia
              matricula.sisben = response.data.sisben
              matricula.familias = response.data.familias
              matricula.estadomatricula  = response.data.estadomatricula
              matricula.procedencia = response.data.procedencia
              matricula.nombreprocedencia = response.data.nombreprocedencia
              matricula.ciudadprocedencia = response.data.ciudadprocedencia
              matricula.observacion = response.data.observacion

              gradosxsede(response.data.empresasede_idempresasede);
              
              axios.get('api/ciudad/'+response.data.ciudadprocedencia).then( response => {
                 departamentoseleccionado.value =  response.data.ciudad_idciudad
              })

            })
        }

        //Consulta si el estudiante tiene una matricula activa en la vigencia(Año) actual 
        const ConsultarMatriculasActivas = () =>{

            axios.get('/api/matriculasactivasestudiante/'+props.id+'/'+matricula.vigencia+'/'+props.idmatricula).then( response => {
              matriculasactivas.value = response.data              
            }).catch( error => {
              console.log(error)
            })
        }

        const NuevaActualizarMatricula = () => {
              
                 mostrar.value=true
                let url = ''  
                if(props.accion=='nueva'){
                  url ='/api/nuevamatricula'          
                }
                if(props.accion=='editar'){
                  url = '/api/actualizarmatricula'
                } 

                  axios.post(url, { data: matricula, matriculasactivas: cambioestadomatriculasactivas.value }).then(response => {                                        

                      if(response.data=="ok"){
                          swal("Bien!", "Información Ingresada Correctamente!", "success");                        
                        }else{                        
                          swal(":(", "Ocurrrio un Error, Intenteemos nuevamente!", "error");                        
                      }

                        mostrar.value=false

                  })
          }

        watch(departamentoseleccionado, () => { 
              axios.get('/api/ciudadxdepto/'+departamentoseleccionado.value).then( response => {
              listaciudades.value = response.data
            })
        })

        watch(() => matricula.empresasede_idempresasede, (id, anterior) => { 
					gradosxsede(id)
        })
        
        watch(() => matricula.vigencia, (vigencia, vigenciaanterior) => { 
			  		ConsultarMatriculasActivas()
			  })

        onMounted(() => {

            sedes()
            jornadas()
            departamentos()

            if(props.idmatricula !== 'matricula')
            {
               ConsultarMatricual()
            }

        })

        return {
                  accion,
                  mostrar, 
                  sedespermitidas, 
                  listajornadas, 
                  listajornadas, 
                  listagrados, 
                  matricula, 
                  listavigencias, 
                  listadepartamentos, 
                  listaciudades, 
                  departamentoseleccionado,                   
                  NuevaActualizarMatricula,
                  matriculasactivas,
                  cambioestadomatriculasactivas,
                  formatoFecha,
                }
        
    }

}
</script>

<style>

</style>
